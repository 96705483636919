import { useState } from 'react';

const initialBoardState = ["", "", "", "", "", "", "", "", ""];

function Square({value, onSquareClick}) {
    
    return <button className="square title" onClick={onSquareClick}>{value}</button>;
}

function Board({ xIsNext, squares, onPlay }) {
  
  function handleClick(i) {
    if (squares[i] || calculateWinner(squares)) {
        return;   
    }
    const nextSquares = squares.slice();
    if (xIsNext) {
        nextSquares[i] = "X";
    } else {
        nextSquares[i] = "O";   
    }
    onPlay(nextSquares);
  }
  
  const winner = calculateWinner(squares);
  let status;
  if (winner) {
      status = "Winner: " + winner;
  } else {
      status = "Next player: " + (xIsNext ? "X" : "O");
  }

  const resetGame = () => {
    squares = initialBoardState;
    //status = "Next Player: O";
  };
  
  return (
    <>
      <h1 className="title bd-container">Tic Tac Toe</h1>
      <h1 className="title bd-container">{status}</h1>
      <div className="container bd-container">
        <div className="board">
      <div className="row">
        <Square value={squares[0]} onSquareClick={() => handleClick(0)}/>
        <Square value={squares[1]} onSquareClick={() => handleClick(1)}/>
        <Square value={squares[2]} onSquareClick={() => handleClick(2)}/>
      </div>
      <div className="row">
        <Square value={squares[3]} onSquareClick={() => handleClick(3)}/>
        <Square value={squares[4]} onSquareClick={() => handleClick(4)}/>
        <Square value={squares[5]} onSquareClick={() => handleClick(5)}/>
      </div>
      <div className="row">
        <Square value={squares[6]} onSquareClick={() => handleClick(6)}/>
        <Square value={squares[7]} onSquareClick={() => handleClick(7)}/>
        <Square value={squares[8]} onSquareClick={() => handleClick(8)}/>
      </div>
      <div className="reset__button">
        <br/><br/>
            <a href="/" onClick={resetGame}>
              Reset Game
            </a>
          </div>
      </div>
      </div>
    </>
  );
}


export default function Game() {
    const [history, setHistory] = useState([Array(9).fill(null)]);
    const [currentMove, setCurrentMove] = useState(0);
    const xIsNext = currentMove % 2 === 0;
    const currentSquares = history[currentMove];
    
    function handlePlay(nextSquares) {
        const nextHistory = [...history.slice(0, currentMove +1), nextSquares];
        setHistory([...history, nextSquares]);
        setCurrentMove(nextHistory.length -1);
    }
    
    /* function jumpTo(nextMove) {
        setCurrentMove(nextMove);
    } */
    
    /* const moves = history.map((squares, move) => {
        let description;
        if (move >0) {
            description = 'Go to move #' + move;
        } else {
            description = 'Go to game start';
        }
        return (
                <option value={move}>{description}</option>
        );
    }); */

  return (
    <div className="game">
      <div className="game-board">
        <Board  xIsNext={xIsNext} squares={currentSquares} onPlay={handlePlay}/>
        {/* <div className="game-info">
          <select name='moves' id='moves' className='select' onChange={() => jumpTo(document.querySelector('#moves').value)}>{moves}</select>
        </div> */}
      </div>

    </div>
  );
}

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[b] === squares[c]) {
            return squares[a];
        }
    }
    return null;
}
